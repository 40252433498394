:root {
  --background-body: #fff;
  --background: #efefef;
  --background-alt: #f7f7f7;
  --selection: #9e9e9e;
  --text-main: #363636;
  --text-bright: #000;
  --text-muted: #70777f;
  --links: #0076d1;
  --focus: #0096bfab;
  --border: #dbdbdb;
  --code: #000;
  --animation-duration: .1s;
  --button-base: #d0cfcf;
  --button-hover: #9b9b9b;
  --scrollbar-thumb: #aaa;
  --scrollbar-thumb-hover: var(--button-hover);
  --form-placeholder: #949494;
  --form-text: #1d1d1d;
  --variable: #39a33c;
  --highlight: #ff0;
  --select-arrow: url("data:image/svg+xml;charset=utf-8,%3C?xml version='1.0' encoding='utf-8'?%3E %3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' height='62.5' width='116.9' fill='%23161f27'%3E %3Cpath d='M115.3,1.6 C113.7,0 111.1,0 109.5,1.6 L58.5,52.7 L7.4,1.6 C5.8,0 3.2,0 1.6,1.6 C0,3.2 0,5.8 1.6,7.4 L55.5,61.3 C56.3,62.1 57.3,62.5 58.4,62.5 C59.4,62.5 60.5,62.1 61.3,61.3 L115.2,7.4 C116.9,5.8 116.9,3.2 115.3,1.6Z'/%3E %3C/svg%3E");
}

@media (prefers-color-scheme: dark) {
  :root {
    --background-body: #202b38;
    --background: #161f27;
    --background-alt: #1a242f;
    --selection: #1c76c5;
    --text-main: #dbdbdb;
    --text-bright: #fff;
    --text-muted: #a9b1ba;
    --links: #41adff;
    --focus: #0096bfab;
    --border: #526980;
    --code: #ffbe85;
    --animation-duration: .1s;
    --button-base: #0c151c;
    --button-hover: #040a0f;
    --scrollbar-thumb: var(--button-hover);
    --scrollbar-thumb-hover: #000;
    --form-placeholder: #a9a9a9;
    --form-text: #fff;
    --variable: #d941e2;
    --highlight: #efdb43;
    --select-arrow: url("data:image/svg+xml;charset=utf-8,%3C?xml version='1.0' encoding='utf-8'?%3E %3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' height='62.5' width='116.9' fill='%23efefef'%3E %3Cpath d='M115.3,1.6 C113.7,0 111.1,0 109.5,1.6 L58.5,52.7 L7.4,1.6 C5.8,0 3.2,0 1.6,1.6 C0,3.2 0,5.8 1.6,7.4 L55.5,61.3 C56.3,62.1 57.3,62.5 58.4,62.5 C59.4,62.5 60.5,62.1 61.3,61.3 L115.2,7.4 C116.9,5.8 116.9,3.2 115.3,1.6Z'/%3E %3C/svg%3E");
  }
}

html {
  scrollbar-color: #aaa #fff;
  scrollbar-color: var(--scrollbar-thumb) var(--background-body);
  scrollbar-width: thin;
}

@media (prefers-color-scheme: dark) {
  html {
    scrollbar-color: #040a0f #202b38;
    scrollbar-color: var(--scrollbar-thumb) var(--background-body);
    scrollbar-color: #040a0f #202b38;
    scrollbar-color: var(--scrollbar-thumb) var(--background-body);
    scrollbar-color: #040a0f #202b38;
    scrollbar-color: var(--scrollbar-thumb) var(--background-body);
    scrollbar-color: #040a0f #202b38;
    scrollbar-color: var(--scrollbar-thumb) var(--background-body);
    scrollbar-color: #040a0f #202b38;
    scrollbar-color: var(--scrollbar-thumb) var(--background-body);
    scrollbar-color: #040a0f #202b38;
    scrollbar-color: var(--scrollbar-thumb) var(--background-body);
  }
}

body {
  max-width: 800px;
  word-wrap: break-word;
  color: #363636;
  color: var(--text-main);
  background: #fff;
  background: var(--background-body);
  text-rendering: optimizelegibility;
  margin: 20px auto;
  padding: 0 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Segoe UI Emoji, Apple Color Emoji, Noto Color Emoji, sans-serif;
  line-height: 1.4;
}

@media (prefers-color-scheme: dark) {
  body {
    background: #202b38;
    background: var(--background-body);
    color: #dbdbdb;
    color: var(--text-main);
  }
}

button {
  transition: background-color .1s linear, border-color .1s linear, color .1s linear, box-shadow .1s linear, transform .1s;
  transition: background-color var(--animation-duration) linear, border-color var(--animation-duration) linear, color var(--animation-duration) linear, box-shadow var(--animation-duration) linear, transform var(--animation-duration) ease;
}

@media (prefers-color-scheme: dark) {
  button {
    transition: background-color .1s linear, border-color .1s linear, color .1s linear, box-shadow .1s linear, transform .1s;
    transition: background-color var(--animation-duration) linear, border-color var(--animation-duration) linear, color var(--animation-duration) linear, box-shadow var(--animation-duration) linear, transform var(--animation-duration) ease;
  }
}

input {
  transition: background-color .1s linear, border-color .1s linear, color .1s linear, box-shadow .1s linear, transform .1s;
  transition: background-color var(--animation-duration) linear, border-color var(--animation-duration) linear, color var(--animation-duration) linear, box-shadow var(--animation-duration) linear, transform var(--animation-duration) ease;
}

@media (prefers-color-scheme: dark) {
  input {
    transition: background-color .1s linear, border-color .1s linear, color .1s linear, box-shadow .1s linear, transform .1s;
    transition: background-color var(--animation-duration) linear, border-color var(--animation-duration) linear, color var(--animation-duration) linear, box-shadow var(--animation-duration) linear, transform var(--animation-duration) ease;
  }
}

textarea {
  transition: background-color .1s linear, border-color .1s linear, color .1s linear, box-shadow .1s linear, transform .1s;
  transition: background-color var(--animation-duration) linear, border-color var(--animation-duration) linear, color var(--animation-duration) linear, box-shadow var(--animation-duration) linear, transform var(--animation-duration) ease;
}

@media (prefers-color-scheme: dark) {
  textarea {
    transition: background-color .1s linear, border-color .1s linear, color .1s linear, box-shadow .1s linear, transform .1s;
    transition: background-color var(--animation-duration) linear, border-color var(--animation-duration) linear, color var(--animation-duration) linear, box-shadow var(--animation-duration) linear, transform var(--animation-duration) ease;
  }
}

h1 {
  margin-top: 0;
  font-size: 2.2em;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 24px;
  margin-bottom: 12px;
}

h1 {
  color: #000;
  color: var(--text-bright);
}

@media (prefers-color-scheme: dark) {
  h1 {
    color: #fff;
    color: var(--text-bright);
  }
}

h2 {
  color: #000;
  color: var(--text-bright);
}

@media (prefers-color-scheme: dark) {
  h2 {
    color: #fff;
    color: var(--text-bright);
  }
}

h3 {
  color: #000;
  color: var(--text-bright);
}

@media (prefers-color-scheme: dark) {
  h3 {
    color: #fff;
    color: var(--text-bright);
  }
}

h4 {
  color: #000;
  color: var(--text-bright);
}

@media (prefers-color-scheme: dark) {
  h4 {
    color: #fff;
    color: var(--text-bright);
  }
}

h5 {
  color: #000;
  color: var(--text-bright);
}

@media (prefers-color-scheme: dark) {
  h5 {
    color: #fff;
    color: var(--text-bright);
  }
}

h6 {
  color: #000;
  color: var(--text-bright);
}

@media (prefers-color-scheme: dark) {
  h6 {
    color: #fff;
    color: var(--text-bright);
  }
}

strong {
  color: #000;
  color: var(--text-bright);
}

@media (prefers-color-scheme: dark) {
  strong {
    color: #fff;
    color: var(--text-bright);
  }
}

h1, h2, h3, h4, h5, h6, b, strong, th {
  font-weight: 600;
}

q:before, q:after {
  content: none;
}

blockquote {
  border-left: 4px solid #0096bfab;
  border-left: 4px solid var(--focus);
  margin: 1.5em 0;
  padding: .5em 1em;
  font-style: italic;
}

@media (prefers-color-scheme: dark) {
  blockquote {
    border-left: 4px solid #0096bfab;
    border-left: 4px solid var(--focus);
  }
}

q {
  border-left: 4px solid #0096bfab;
  border-left: 4px solid var(--focus);
  margin: 1.5em 0;
  padding: .5em 1em;
  font-style: italic;
}

@media (prefers-color-scheme: dark) {
  q {
    border-left: 4px solid #0096bfab;
    border-left: 4px solid var(--focus);
  }
}

blockquote > footer {
  border: 0;
  font-style: normal;
}

blockquote cite, address {
  font-style: normal;
}

a[href^="mailto:"]:before {
  content: "📧 ";
}

a[href^="tel:"]:before {
  content: "📞 ";
}

a[href^="sms:"]:before {
  content: "💬 ";
}

mark {
  background-color: #ff0;
  background-color: var(--highlight);
  color: #000;
  border-radius: 2px;
  padding: 0 2px;
}

@media (prefers-color-scheme: dark) {
  mark {
    background-color: #efdb43;
    background-color: var(--highlight);
  }
}

a > code, a > strong {
  color: inherit;
}

button, select, input[type="submit"], input[type="reset"], input[type="button"], input[type="checkbox"], input[type="range"], input[type="radio"] {
  cursor: pointer;
}

input, select {
  display: block;
}

[type="checkbox"], [type="radio"] {
  display: initial;
}

input {
  color: #1d1d1d;
  color: var(--form-text);
  background-color: #efefef;
  background-color: var(--background);
  font-family: inherit;
  font-size: inherit;
  border: none;
  border-radius: 6px;
  outline: none;
  margin-bottom: 6px;
  margin-right: 6px;
  padding: 10px;
}

@media (prefers-color-scheme: dark) {
  input {
    background-color: #161f27;
    background-color: var(--background);
    color: #fff;
    color: var(--form-text);
  }
}

button {
  color: #1d1d1d;
  color: var(--form-text);
  background-color: #efefef;
  background-color: var(--background);
  font-family: inherit;
  font-size: inherit;
  border: none;
  border-radius: 6px;
  outline: none;
  margin-bottom: 6px;
  margin-right: 6px;
  padding: 10px;
}

@media (prefers-color-scheme: dark) {
  button {
    background-color: #161f27;
    background-color: var(--background);
    color: #fff;
    color: var(--form-text);
  }
}

textarea {
  color: #1d1d1d;
  color: var(--form-text);
  background-color: #efefef;
  background-color: var(--background);
  font-family: inherit;
  font-size: inherit;
  border: none;
  border-radius: 6px;
  outline: none;
  margin-bottom: 6px;
  margin-right: 6px;
  padding: 10px;
}

@media (prefers-color-scheme: dark) {
  textarea {
    background-color: #161f27;
    background-color: var(--background);
    color: #fff;
    color: var(--form-text);
  }
}

select {
  color: #1d1d1d;
  color: var(--form-text);
  background-color: #efefef;
  background-color: var(--background);
  font-family: inherit;
  font-size: inherit;
  border: none;
  border-radius: 6px;
  outline: none;
  margin-bottom: 6px;
  margin-right: 6px;
  padding: 10px;
}

@media (prefers-color-scheme: dark) {
  select {
    background-color: #161f27;
    background-color: var(--background);
    color: #fff;
    color: var(--form-text);
  }
}

button {
  background-color: #d0cfcf;
  background-color: var(--button-base);
  padding-left: 30px;
  padding-right: 30px;
}

@media (prefers-color-scheme: dark) {
  button {
    background-color: #0c151c;
    background-color: var(--button-base);
  }
}

input[type="submit"] {
  background-color: #d0cfcf;
  background-color: var(--button-base);
  padding-left: 30px;
  padding-right: 30px;
}

@media (prefers-color-scheme: dark) {
  input[type="submit"] {
    background-color: #0c151c;
    background-color: var(--button-base);
  }
}

input[type="reset"] {
  background-color: #d0cfcf;
  background-color: var(--button-base);
  padding-left: 30px;
  padding-right: 30px;
}

@media (prefers-color-scheme: dark) {
  input[type="reset"] {
    background-color: #0c151c;
    background-color: var(--button-base);
  }
}

input[type="button"] {
  background-color: #d0cfcf;
  background-color: var(--button-base);
  padding-left: 30px;
  padding-right: 30px;
}

@media (prefers-color-scheme: dark) {
  input[type="button"] {
    background-color: #0c151c;
    background-color: var(--button-base);
  }
}

button:hover {
  background: #9b9b9b;
  background: var(--button-hover);
}

@media (prefers-color-scheme: dark) {
  button:hover {
    background: #040a0f;
    background: var(--button-hover);
  }
}

input[type="submit"]:hover {
  background: #9b9b9b;
  background: var(--button-hover);
}

@media (prefers-color-scheme: dark) {
  input[type="submit"]:hover {
    background: #040a0f;
    background: var(--button-hover);
  }
}

input[type="reset"]:hover {
  background: #9b9b9b;
  background: var(--button-hover);
}

@media (prefers-color-scheme: dark) {
  input[type="reset"]:hover {
    background: #040a0f;
    background: var(--button-hover);
  }
}

input[type="button"]:hover {
  background: #9b9b9b;
  background: var(--button-hover);
}

@media (prefers-color-scheme: dark) {
  input[type="button"]:hover {
    background: #040a0f;
    background: var(--button-hover);
  }
}

input[type="color"] {
  min-height: 2rem;
  cursor: pointer;
  padding: 8px;
}

input[type="checkbox"], input[type="radio"] {
  height: 1em;
  width: 1em;
}

input[type="radio"] {
  border-radius: 100%;
}

input {
  vertical-align: top;
}

label {
  vertical-align: middle;
  margin-bottom: 4px;
  display: inline-block;
}

input:not([type="checkbox"]):not([type="radio"]), input[type="range"], select, button, textarea {
  -webkit-appearance: none;
}

textarea {
  box-sizing: border-box;
  resize: vertical;
  margin-right: 0;
  display: block;
}

textarea:not([cols]) {
  width: 100%;
}

textarea:not([rows]) {
  min-height: 40px;
  height: 140px;
}

select {
  background: #efefef url("data:image/svg+xml;charset=utf-8,%3C?xml version='1.0' encoding='utf-8'?%3E %3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' height='62.5' width='116.9' fill='%23161f27'%3E %3Cpath d='M115.3,1.6 C113.7,0 111.1,0 109.5,1.6 L58.5,52.7 L7.4,1.6 C5.8,0 3.2,0 1.6,1.6 C0,3.2 0,5.8 1.6,7.4 L55.5,61.3 C56.3,62.1 57.3,62.5 58.4,62.5 C59.4,62.5 60.5,62.1 61.3,61.3 L115.2,7.4 C116.9,5.8 116.9,3.2 115.3,1.6Z'/%3E %3C/svg%3E") calc(100% - 12px) / 12px no-repeat;
  background: var(--background) var(--select-arrow) calc(100% - 12px) 50% / 12px no-repeat;
  padding-right: 35px;
}

@media (prefers-color-scheme: dark) {
  select {
    background: #161f27 url("data:image/svg+xml;charset=utf-8,%3C?xml version='1.0' encoding='utf-8'?%3E %3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' height='62.5' width='116.9' fill='%23efefef'%3E %3Cpath d='M115.3,1.6 C113.7,0 111.1,0 109.5,1.6 L58.5,52.7 L7.4,1.6 C5.8,0 3.2,0 1.6,1.6 C0,3.2 0,5.8 1.6,7.4 L55.5,61.3 C56.3,62.1 57.3,62.5 58.4,62.5 C59.4,62.5 60.5,62.1 61.3,61.3 L115.2,7.4 C116.9,5.8 116.9,3.2 115.3,1.6Z'/%3E %3C/svg%3E") calc(100% - 12px) / 12px no-repeat;
    background: var(--background) var(--select-arrow) calc(100% - 12px) 50% / 12px no-repeat;
    background: #161f27 url("data:image/svg+xml;charset=utf-8,%3C?xml version='1.0' encoding='utf-8'?%3E %3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' height='62.5' width='116.9' fill='%23efefef'%3E %3Cpath d='M115.3,1.6 C113.7,0 111.1,0 109.5,1.6 L58.5,52.7 L7.4,1.6 C5.8,0 3.2,0 1.6,1.6 C0,3.2 0,5.8 1.6,7.4 L55.5,61.3 C56.3,62.1 57.3,62.5 58.4,62.5 C59.4,62.5 60.5,62.1 61.3,61.3 L115.2,7.4 C116.9,5.8 116.9,3.2 115.3,1.6Z'/%3E %3C/svg%3E") calc(100% - 12px) / 12px no-repeat;
    background: var(--background) var(--select-arrow) calc(100% - 12px) 50% / 12px no-repeat;
    background: #161f27 url("data:image/svg+xml;charset=utf-8,%3C?xml version='1.0' encoding='utf-8'?%3E %3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' height='62.5' width='116.9' fill='%23efefef'%3E %3Cpath d='M115.3,1.6 C113.7,0 111.1,0 109.5,1.6 L58.5,52.7 L7.4,1.6 C5.8,0 3.2,0 1.6,1.6 C0,3.2 0,5.8 1.6,7.4 L55.5,61.3 C56.3,62.1 57.3,62.5 58.4,62.5 C59.4,62.5 60.5,62.1 61.3,61.3 L115.2,7.4 C116.9,5.8 116.9,3.2 115.3,1.6Z'/%3E %3C/svg%3E") calc(100% - 12px) / 12px no-repeat;
    background: var(--background) var(--select-arrow) calc(100% - 12px) 50% / 12px no-repeat;
    background: #161f27 url("data:image/svg+xml;charset=utf-8,%3C?xml version='1.0' encoding='utf-8'?%3E %3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' height='62.5' width='116.9' fill='%23efefef'%3E %3Cpath d='M115.3,1.6 C113.7,0 111.1,0 109.5,1.6 L58.5,52.7 L7.4,1.6 C5.8,0 3.2,0 1.6,1.6 C0,3.2 0,5.8 1.6,7.4 L55.5,61.3 C56.3,62.1 57.3,62.5 58.4,62.5 C59.4,62.5 60.5,62.1 61.3,61.3 L115.2,7.4 C116.9,5.8 116.9,3.2 115.3,1.6Z'/%3E %3C/svg%3E") calc(100% - 12px) / 12px no-repeat;
    background: var(--background) var(--select-arrow) calc(100% - 12px) 50% / 12px no-repeat;
  }
}

select::-ms-expand {
  display: none;
}

select[multiple] {
  background-image: none;
  padding-right: 10px;
  overflow-y: auto;
}

input:focus {
  box-shadow: 0 0 0 2px #0096bfab;
  box-shadow: 0 0 0 2px var(--focus);
}

@media (prefers-color-scheme: dark) {
  input:focus {
    box-shadow: 0 0 0 2px #0096bfab;
    box-shadow: 0 0 0 2px var(--focus);
  }
}

select:focus {
  box-shadow: 0 0 0 2px #0096bfab;
  box-shadow: 0 0 0 2px var(--focus);
}

@media (prefers-color-scheme: dark) {
  select:focus {
    box-shadow: 0 0 0 2px #0096bfab;
    box-shadow: 0 0 0 2px var(--focus);
  }
}

button:focus {
  box-shadow: 0 0 0 2px #0096bfab;
  box-shadow: 0 0 0 2px var(--focus);
}

@media (prefers-color-scheme: dark) {
  button:focus {
    box-shadow: 0 0 0 2px #0096bfab;
    box-shadow: 0 0 0 2px var(--focus);
  }
}

textarea:focus {
  box-shadow: 0 0 0 2px #0096bfab;
  box-shadow: 0 0 0 2px var(--focus);
}

@media (prefers-color-scheme: dark) {
  textarea:focus {
    box-shadow: 0 0 0 2px #0096bfab;
    box-shadow: 0 0 0 2px var(--focus);
  }
}

input[type="checkbox"]:active, input[type="radio"]:active, input[type="submit"]:active, input[type="reset"]:active, input[type="button"]:active, input[type="range"]:active, button:active {
  transform: translateY(2px);
}

input:disabled, select:disabled, button:disabled, textarea:disabled {
  cursor: not-allowed;
  opacity: .5;
}

::-moz-placeholder {
  color: #949494;
  color: var(--form-placeholder);
}

:-ms-input-placeholder {
  color: #949494;
  color: var(--form-placeholder);
}

::placeholder {
  color: #949494;
  color: var(--form-placeholder);
}

@media (prefers-color-scheme: dark) {
  ::-moz-placeholder {
    color: #a9a9a9;
    color: var(--form-placeholder);
  }

  :-ms-input-placeholder {
    color: #a9a9a9;
    color: var(--form-placeholder);
  }

  ::placeholder {
    color: #a9a9a9;
    color: var(--form-placeholder);
  }
}

fieldset {
  border: 1px solid #0096bfab;
  border: 1px var(--focus) solid;
  border-radius: 6px;
  margin: 0 0 12px;
  padding: 10px;
}

@media (prefers-color-scheme: dark) {
  fieldset {
    border: 1px solid #0096bfab;
    border: 1px var(--focus) solid;
  }
}

legend {
  font-size: .9em;
  font-weight: 600;
}

input[type="range"] {
  background: none;
  margin: 10px 0;
  padding: 10px 0;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 9.5px;
  background: #efefef;
  background: var(--background);
  border-radius: 3px;
  transition: all .2s;
}

@media (prefers-color-scheme: dark) {
  input[type="range"]::-webkit-slider-runnable-track {
    background: #161f27;
    background: var(--background);
  }
}

input[type="range"]::-webkit-slider-thumb {
  height: 20px;
  width: 20px;
  background: #dbdbdb;
  background: var(--border);
  -webkit-appearance: none;
  border-radius: 50%;
  margin-top: -7px;
  box-shadow: 0 1px 1px #000, 0 0 1px #0d0d0d;
}

@media (prefers-color-scheme: dark) {
  input[type="range"]::-webkit-slider-thumb {
    background: #526980;
    background: var(--border);
  }
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #efefef;
  background: var(--background);
}

@media (prefers-color-scheme: dark) {
  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #161f27;
    background: var(--background);
  }
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 9.5px;
  background: #efefef;
  background: var(--background);
  border-radius: 3px;
  transition: all .2s;
}

@media (prefers-color-scheme: dark) {
  input[type="range"]::-moz-range-track {
    background: #161f27;
    background: var(--background);
  }
}

input[type="range"]::-moz-range-thumb {
  height: 20px;
  width: 20px;
  background: #dbdbdb;
  background: var(--border);
  border-radius: 50%;
  box-shadow: 1px 1px 1px #000, 0 0 1px #0d0d0d;
}

@media (prefers-color-scheme: dark) {
  input[type="range"]::-moz-range-thumb {
    background: #526980;
    background: var(--border);
  }
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 9.5px;
  color: #0000;
  background: none;
  border-width: 16px 0;
  border-color: #0000;
}

input[type="range"]::-ms-fill-lower {
  background: #efefef;
  background: var(--background);
  border: .2px solid #010101;
  border-radius: 3px;
  box-shadow: 1px 1px 1px #000, 0 0 1px #0d0d0d;
}

@media (prefers-color-scheme: dark) {
  input[type="range"]::-ms-fill-lower {
    background: #161f27;
    background: var(--background);
  }
}

input[type="range"]::-ms-fill-upper {
  background: #efefef;
  background: var(--background);
  border: .2px solid #010101;
  border-radius: 3px;
  box-shadow: 1px 1px 1px #000, 0 0 1px #0d0d0d;
}

@media (prefers-color-scheme: dark) {
  input[type="range"]::-ms-fill-upper {
    background: #161f27;
    background: var(--background);
  }
}

input[type="range"]::-ms-thumb {
  height: 20px;
  width: 20px;
  background: #dbdbdb;
  background: var(--border);
  border: 1px solid #000;
  border-radius: 50%;
  box-shadow: 1px 1px 1px #000, 0 0 1px #0d0d0d;
}

@media (prefers-color-scheme: dark) {
  input[type="range"]::-ms-thumb {
    background: #526980;
    background: var(--border);
  }
}

input[type="range"]:focus::-ms-fill-lower {
  background: #efefef;
  background: var(--background);
}

@media (prefers-color-scheme: dark) {
  input[type="range"]:focus::-ms-fill-lower {
    background: #161f27;
    background: var(--background);
  }
}

input[type="range"]:focus::-ms-fill-upper {
  background: #efefef;
  background: var(--background);
}

@media (prefers-color-scheme: dark) {
  input[type="range"]:focus::-ms-fill-upper {
    background: #161f27;
    background: var(--background);
  }
}

a {
  color: #0076d1;
  color: var(--links);
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  a {
    color: #41adff;
    color: var(--links);
  }
}

a:hover {
  text-decoration: underline;
}

code {
  background: #efefef;
  background: var(--background);
  color: #000;
  color: var(--code);
  border-radius: 6px;
  padding: 2.5px 5px;
  font-size: 1em;
}

@media (prefers-color-scheme: dark) {
  code {
    color: #ffbe85;
    color: var(--code);
    background: #161f27;
    background: var(--background);
  }
}

samp {
  background: #efefef;
  background: var(--background);
  color: #000;
  color: var(--code);
  border-radius: 6px;
  padding: 2.5px 5px;
  font-size: 1em;
}

@media (prefers-color-scheme: dark) {
  samp {
    color: #ffbe85;
    color: var(--code);
    background: #161f27;
    background: var(--background);
  }
}

time {
  background: #efefef;
  background: var(--background);
  color: #000;
  color: var(--code);
  border-radius: 6px;
  padding: 2.5px 5px;
  font-size: 1em;
}

@media (prefers-color-scheme: dark) {
  time {
    color: #ffbe85;
    color: var(--code);
    background: #161f27;
    background: var(--background);
  }
}

pre > code {
  padding: 10px;
  display: block;
  overflow-x: auto;
}

var {
  color: #39a33c;
  color: var(--variable);
  font-family: monospace;
  font-style: normal;
}

@media (prefers-color-scheme: dark) {
  var {
    color: #d941e2;
    color: var(--variable);
  }
}

kbd {
  background: #efefef;
  background: var(--background);
  border: 1px solid #dbdbdb;
  border: 1px solid var(--border);
  color: #363636;
  color: var(--text-main);
  border-radius: 2px;
  padding: 2px 4px;
}

@media (prefers-color-scheme: dark) {
  kbd {
    color: #dbdbdb;
    color: var(--text-main);
    border: 1px solid #526980;
    border: 1px solid var(--border);
    background: #161f27;
    background: var(--background);
  }
}

img, video {
  max-width: 100%;
  height: auto;
}

hr {
  border: none;
  border-top: 1px solid #dbdbdb;
  border-top: 1px solid var(--border);
}

@media (prefers-color-scheme: dark) {
  hr {
    border-top: 1px solid #526980;
    border-top: 1px solid var(--border);
  }
}

table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  margin-bottom: 10px;
}

table caption {
  text-align: left;
}

td, th {
  text-align: left;
  vertical-align: top;
  word-wrap: break-word;
  padding: 6px;
}

thead {
  border-bottom: 1px solid #dbdbdb;
  border-bottom: 1px solid var(--border);
}

@media (prefers-color-scheme: dark) {
  thead {
    border-bottom: 1px solid #526980;
    border-bottom: 1px solid var(--border);
  }
}

tfoot {
  border-top: 1px solid #dbdbdb;
  border-top: 1px solid var(--border);
}

@media (prefers-color-scheme: dark) {
  tfoot {
    border-top: 1px solid #526980;
    border-top: 1px solid var(--border);
  }
}

tbody tr:nth-child(2n) {
  background-color: #efefef;
  background-color: var(--background);
}

@media (prefers-color-scheme: dark) {
  tbody tr:nth-child(2n) {
    background-color: #161f27;
    background-color: var(--background);
  }
}

tbody tr:nth-child(2n) button {
  background-color: #f7f7f7;
  background-color: var(--background-alt);
}

@media (prefers-color-scheme: dark) {
  tbody tr:nth-child(2n) button {
    background-color: #1a242f;
    background-color: var(--background-alt);
  }
}

tbody tr:nth-child(2n) button:hover {
  background-color: #fff;
  background-color: var(--background-body);
}

@media (prefers-color-scheme: dark) {
  tbody tr:nth-child(2n) button:hover {
    background-color: #202b38;
    background-color: var(--background-body);
  }
}

::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #efefef;
  background: var(--background);
  border-radius: 6px;
}

@media (prefers-color-scheme: dark) {
  ::-webkit-scrollbar-track {
    background: #161f27;
    background: var(--background);
  }
}

::-webkit-scrollbar-thumb {
  background: #aaa;
  background: var(--scrollbar-thumb);
  border-radius: 6px;
}

@media (prefers-color-scheme: dark) {
  ::-webkit-scrollbar-thumb {
    background: #040a0f;
    background: var(--scrollbar-thumb);
  }

  ::-webkit-scrollbar-thumb {
    background: #040a0f;
    background: var(--scrollbar-thumb);
  }
}

::-webkit-scrollbar-thumb:hover {
  background: #9b9b9b;
  background: var(--scrollbar-thumb-hover);
}

@media (prefers-color-scheme: dark) {
  ::-webkit-scrollbar-thumb:hover {
    background: #000;
    background: var(--scrollbar-thumb-hover);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #000;
    background: var(--scrollbar-thumb-hover);
  }
}

::selection {
  background-color: #9e9e9e;
  background-color: var(--selection);
  color: #000;
  color: var(--text-bright);
}

@media (prefers-color-scheme: dark) {
  ::-moz-selection {
    color: #fff;
    color: var(--text-bright);
  }

  ::selection {
    background-color: #1c76c5;
    background-color: var(--selection);
  }
}

details {
  background-color: #f7f7f7;
  background-color: var(--background-alt);
  border-radius: 6px;
  flex-direction: column;
  align-items: flex-start;
  margin: 1em 0;
  padding: 10px 10px 0;
  display: flex;
  overflow: hidden;
}

@media (prefers-color-scheme: dark) {
  details {
    background-color: #1a242f;
    background-color: var(--background-alt);
  }
}

details[open] {
  padding: 10px;
}

details > :last-child {
  margin-bottom: 0;
}

details[open] summary {
  margin-bottom: 10px;
}

summary {
  background-color: #efefef;
  background-color: var(--background);
  cursor: pointer;
  outline: none;
  margin: -10px -10px 0;
  padding: 10px;
  display: list-item;
}

@media (prefers-color-scheme: dark) {
  summary {
    background-color: #161f27;
    background-color: var(--background);
  }
}

summary:hover, summary:focus {
  text-decoration: underline;
}

details > :not(summary) {
  margin-top: 0;
}

summary::-webkit-details-marker {
  color: #363636;
  color: var(--text-main);
}

@media (prefers-color-scheme: dark) {
  summary::-webkit-details-marker {
    color: #dbdbdb;
    color: var(--text-main);
  }
}

dialog {
  background-color: #f7f7f7;
  background-color: var(--background-alt);
  color: #363636;
  color: var(--text-main);
  border: #dbdbdb;
  border-color: var(--border);
  border-radius: 6px;
  padding: 10px 30px;
}

@media (prefers-color-scheme: dark) {
  dialog {
    border-color: #526980;
    border-color: var(--border);
    color: #dbdbdb;
    color: var(--text-main);
    background-color: #1a242f;
    background-color: var(--background-alt);
  }
}

dialog > header:first-child {
  background-color: #efefef;
  background-color: var(--background);
  text-align: center;
  border-radius: 6px 6px 0 0;
  margin: -10px -30px 10px;
  padding: 10px;
}

@media (prefers-color-scheme: dark) {
  dialog > header:first-child {
    background-color: #161f27;
    background-color: var(--background);
  }
}

dialog::backdrop {
  -webkit-backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background: #0000009c;
}

footer {
  border-top: 1px solid #dbdbdb;
  border-top: 1px solid var(--border);
  color: #70777f;
  color: var(--text-muted);
  padding-top: 10px;
}

@media (prefers-color-scheme: dark) {
  footer {
    color: #a9b1ba;
    color: var(--text-muted);
    border-top: 1px solid #526980;
    border-top: 1px solid var(--border);
  }
}

body > footer {
  margin-top: 40px;
}

@media print {
  body, pre, code, summary, details, button, input, textarea {
    background-color: #fff;
  }

  button, input, textarea {
    border: 1px solid #000;
  }

  body, h1, h2, h3, h4, h5, h6, pre, code, button, input, textarea, footer, summary, strong {
    color: #000;
  }

  summary::marker {
    color: #000;
  }

  summary::-webkit-details-marker {
    color: #000;
  }

  tbody tr:nth-child(2n) {
    background-color: #f2f2f2;
  }

  a {
    color: #00f;
    text-decoration: underline;
  }
}

/*# sourceMappingURL=index.9759dc00.css.map */
